import img1 from '../images/home/1.jpg';
import img2 from '../images/home/2.png';
import img3 from '../images/home/3.jpg';
import img4 from '../images/home/4.jpg';
import img5 from '../images/home/5.jpg';


export default function ImageContainer(){
    return (<>
      <div className='imageContainer'>
        <img src={img1} alt="bar"/>
        <img src={img2} alt="bao"/>
        <img src={img3} alt="bao-buns"/>
        <img src={img4} alt="restaurang"/>
        <img src={img5} alt="restaurang"/>
      </div>
      </>
    )
}