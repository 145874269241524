import instagram from '../images/social-media/instagram.png';
import facebook from '../images/social-media/facebook.png';
import tiktok from '../images/social-media/tiktok.png';
import youtube from '../images/social-media/youtube.png';

export default function Footer() {
  const specialOpenings = false;
  return (
    <div className="footerContainer">
      <div className="footerContent">
        <div className="footerContactContainer">
          {specialOpenings && (
            <div className="specialOpenings">
              15/8-2024 har vi öppet 11:00-14:30
            </div>
          )}
          <div className="openingHours">
            <div>
              <p className="pDay">MÅNDAG - TORSDAG</p>
              <p className="pTime">11:00 - 20:00</p>
            </div>
            <div>
              <p className="pDay">FREDAG</p>
              <p className="pTime">11:00 - 21:00</p>
            </div>
            <div>
              <p className="pDay">LÖRDAG</p>
              <p className="pTime">12:00 - 21:00</p>
            </div>
            <div>
              <p className="pDay">SÖNDAG</p>
              <p className="pTime">STÄNGT</p>
            </div>
          </div>

          <div className="footerWrapper">
            <div className="addressList">
              <p>
                BIFROSTGATAN 50A
                <br />
                431 44 MÖLNDAL
                <br />
                GÖTEBORG, SVERIGE
              </p>
            </div>
            <div className="footerContactList">
              <p>
                INFO@FUNDINING.SE
                <br />
                031- 463 888
              </p>
            </div>
            <div className="mapIconsWrapper">
              <div className="MapContainer">
                <div id="my-map-canvas">
                  <iframe
                    title="Google Maps Embed"
                    style={{ height: "100%", width: "100%", border: "0" }}
                    src="https://www.google.com/maps/embed/v1/place?q=Bifrostgatan+50A,+Mölndal,+Sverige&zoom=14&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
              <div className="iconsContainer">
                <img src={facebook} alt="Facebook Logo" />
                <a
                  href="https://www.instagram.com/fundining.se"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagram} alt="Instagram Logo" />
                </a>
                <img src={tiktok} alt="TikTok Logo" />
                <img src={youtube} alt="YouTube Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
